export function windowWidth() {
  return window.innerWidth;
}
export function scrollParams(height: number = windowHeight()) {
  return {height: height + 'px'};
}
export  function scrollTop(){
  window.scrollTo(0,0)
}
export function moveToCenter(windowHeight: any = 100, windowWidth: any = 100, imageHeight: any = 100, imageWidth: any = 100) {
  let centerY: any = windowHeight / 2 - imageHeight / 2;
  let centerX: any = windowWidth / 2 - imageWidth / 2;
  return {x: centerX, y: centerY};
}

export function windowHeight() {
  return window.innerHeight;
}

export function windowHeightPadding(paddingHeight: any = 20) {
  return window.innerHeight / paddingHeight;
}

export function windowWidthPadding(paddingWidth: any = 20) {
  return window.innerWidth / paddingWidth;
}

export function _640_480() {
  return {width: 480, height: 680};
}

export function windowHalfWidth() {
  return window.innerWidth / 2;
}

export function windowHalfHeight() {
  return window.innerHeight / 2;
}

export function sizeWindow() {
  return {width: window.innerWidth, height: window.innerHeight};
}

export function resizeWindow(callback: any) {
  window.addEventListener('resize', (event: any) => {
    callback && callback(
      windowWidth(),
      windowHeight(),
      event
  );
  });
}

export function getUrl() {
  return window.location.href;
}

export function userAgent() {
  return window.navigator.userAgent;
}

export function scrollToId(idFrom: any = 'myButton', idTo: any = 'insert2', offsetTop: any = 125) {
  // @ts-ignore
  let scrollDiv = document.getElementById(idTo).offsetTop - offsetTop;
  window.scrollTo({
    top: scrollDiv,
    behavior: 'smooth'
  });
}

