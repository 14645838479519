import {Component, inject} from '@angular/core';
import {PageDuoServices} from "../../../services/pages/page-duo.services";
import {CommonHomeComponent} from "../../common-home/common-home.component";
import {
  NgClass,
  NgForOf,
  NgStyle
} from "@angular/common";
import {DuoChapterComponent} from "../../../components/duo/duo-chapter/duo-chapter.component";
import {ScrollWrapperComponent} from "../../../components/duo/scroll-wrapper/scroll-wrapper.component";
import {reInit} from "../../../traits/datetime-tools";
import {PageCoursesServices} from "../../../services/pages/page-courses.services";
import {windowHeight} from "../../../traits/window-tools";
// @ts-ignore

@Component({
  selector: 'app-page-duo',
  standalone:true,
  providers:[],
  imports: [
    CommonHomeComponent,
    NgForOf,
    NgStyle,
    NgClass,
    DuoChapterComponent,
    ScrollWrapperComponent,
  ],
  templateUrl: './page-duo.component.html',
})
/*public dataPage: PageDuoServices*/
export class PageDuoComponent {
  public site:any
constructor(public dataPage: PageDuoServices) {
  this.dataPage.getSite((site:any)=>{
    this.site = site
  });

}
  public initCourse() {
    /*if (this.selectCourse) {
      this.lessons = this.selectCourse.array;
      this.lesson = this.selectCourse.array[0];
      this.showResources = true;
      reInit(()=>{
        this.showResources = false;
      })
    }*/
  }


  protected readonly windowHeight = windowHeight;
}
