<!--brushesTools.isShowBrushesHistory()-->

<app-sidebar [show]="showSidebar"
             (onSave)="onClose.emit()"
             [showClose]="true"
             [height]="100"
             [ext]="'%'"
             [position]="'bottom'"
>
  <ng-container *ngIf="showSidebar">
    <div class=" p-1 m-1 grid grid-cols-3 gap-1 ">
      <ng-container *ngFor="let historyItem of  historyBrushList;">
        <div class="item-scroll">

            <img class=" bg-zinc-300 border-1 border-amber-100 rounded-md w-32 h-32"
              (click)="
                     drawCanvas.restoreState(historyItem.srcStroke);
                     onClose.emit()"
              [src]="historyItem.srcStroke" alt="srcStroke">

        </div>
      </ng-container>
    </div>
  </ng-container>
</app-sidebar>




