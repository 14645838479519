import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {
  JsonPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {SidebarComponent} from "../../../common/sidebar/sidebar.component";
import {ActionComponent} from "../../../../pages/pages-jome/page-artas/action/action.component";
import {GifGeneratorComponent} from "../../../image/gif-generator/gif-generator.component";
import {OrderByImageFormComponent} from "../../../../pages/pages-jome/page-artas/order-by-image-form/order-by-image-form.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {SvgIconComponent} from "../../../image/svg-icon/svg-icon.component";
import {TextSliceComponent} from "../../../text-slice/text-slice.component";

@Component({
  selector: 'app-duo-steps',
  standalone:true,
  providers:[],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    SidebarComponent,
    JsonPipe,
    ActionComponent,
    GifGeneratorComponent,
    OrderByImageFormComponent,
    OverlayPanelModule,
    SvgIconComponent,
    TextSliceComponent,
  ],
  templateUrl: './duo-steps.component.html',
  styleUrls: ['./duo-steps.component.scss'],
})
export class DuoStepsComponent {
  public bgImage: string ='assets/background_logo_small.png';
  @Input() steps: any[];
  @Output() public  onClose = new EventEmitter();
  @Output() public  onPrevLesson = new EventEmitter();
  protected showSteps: boolean =false;
}
