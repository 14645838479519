<div class="z-20 progress-container">
<!--  <div id="message">Закрашено точек: <span id="filled-pixels">0</span></div>-->
  <div *ngIf="percentage>0" class=" z-20 absolute bg-amber-500 p-[2px] text-zinc-900 top-[0] font-bold text-[10px] right-0 ">
    <span id="filled-percent">{{ percentage }}</span>%
  </div>
  <div  class=" z-20 absolute bg-amber-500 p-[2px] text-zinc-900 top-[10px] font-bold text-[10px] left-[0] "
        *ngIf="selectAlert">  {{selectAlert.message}}</div>
  <div id="progress-bar">
    <div id="progress"></div>
  </div>
</div>

