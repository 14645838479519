import {CdServices} from "./cd.services";

declare const interact;

export class InteractTools {
  public interact: any;
  public enableDrag: any = true;
  public enableReset: any = false;
  public handlerClass: any = '.drag-handle';
  public handlerResizeClass: any = '.resize-handle';
  public x: any = 5;
  public y: any = 5;
  public scaleMax: number = 5;
  public scaleMin: number = 1;
  public scaleEnabled: any = true;
  public paramsInput: any= {
    scale: 0.5,
    x: 0,
    y: 0,
    angle: 0,
  }

  public paramsOutput: any = {
    scale: 1,
    x: 0,
    y: 0,
    angle: 0,
  }

  constructor(scaleElement:any =null ) {

    let x = (parseFloat(scaleElement.getAttribute('data-x')) || 0)
    let y = (parseFloat(scaleElement.getAttribute('data-y')) || 0)
    console.log(x)
    console.log(y)
   this.paramsInput =  {
      scale: 0.5,
      x: x,
      y: y,
      angle: 0,
    };
    // this.init();
  }

  public init() {
    // let gestureArea = document.getElementById('resize-handle')
    // this.interact = interact(gestureArea);
    // this.interact = interact(this.nativeElement);
  }

  public draggable(callbackStopDrag: any = null) {
    let interactGestureArea: any = interact('.draggable');
    let that: any = this;
    interactGestureArea.draggable({
      autoScroll: true,
      allowFrom: '.drag-handle',

      listeners: {
        init:(event:any)=>{
          let target = event.target;
          let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
          callbackStopDrag && callbackStopDrag({x:x,y:y});
        },
        move: (event: any) => {
            let target = event.target;
            let multiply =  2
            let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx*multiply;
            let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy*multiply;
            target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
            target.setAttribute('data-x', x);
            target.setAttribute('data-y', y);
          },
        end(event: any) {
          if (that.enableDrag) {
            let x = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx;
            let y = (parseFloat(event.target.getAttribute('data-y')) || 0) + event.dy;
            callbackStopDrag && callbackStopDrag({x:x,y:y});
          }
        }
      }
    });
    // this.multiTouch();
    // allowFrom: this.handlerResizeClass,

    // this.resizing()
  }

  public multiTouch(gestureArea: any = null, scaleElement: any = null, callbackStartDrag: any = null, callbackStopDrag: any = null,) {
    let that: any = this;
    let interactWithArea = interact(gestureArea);

    let resetTimeout: any;
    // this.enableReset ?? this.reset(this.paramsInput, scaleElement);
    interactWithArea.gesturable({
      listeners: {
        start(event: any) {
          if (that.enableDrag) {
            callbackStartDrag && callbackStartDrag();
            that.paramsInput.angle -= event.angle;
            clearTimeout(resetTimeout);
            scaleElement.classList.remove('reset');
          }
        },
        move(event: any) {
          if (that.enableDrag) {
            callbackStartDrag && callbackStartDrag();
            let currentAngle: any = event.angle + that.paramsInput.angle;
            let currentScale: any = event.scale * that.paramsInput.scale;
            // scaleElement.style.transform = 'rotate(' + currentAngle + 'deg)' + 'scale(' + currentScale + ')';
            scaleElement.style.transform = 'scale(' + currentScale + ')';
            that.moveHtml(event);

          }
        },
        end(event: any) {
          if (that.enableDrag) {
            that.paramsInput.angle = that.paramsInput.angle + event.angle;
            that.paramsInput.scale = that.paramsInput.scale * event.scale;
            callbackStopDrag && callbackStopDrag(that.paramsInput);
          }
        }
      }
    })


  }

  public reset(angleScale: any, scaleElement: any) {
    scaleElement.style.transform = 'scale(1)';
    this.paramsInput.angle = 0;
    this.paramsInput.scale = 1;
  }

  public scalable(gestureArea: any = null,
                  scaleElement: any = null,
                  callbackStopDrag: any = null,
                  ) {
    let interactWithArea = interact(gestureArea);
    let that: any = this;
    interactWithArea.gesturable({
      allowFrom: '.drag-handle-container',
      listeners: {
        start(event: any) {
          if (that.enableDrag) {

            // callbackStartDrag && callbackStartDrag();
            // clearTimeout(resetTimeout);
            // scaleElement.classList.remove('reset');
          }
        },
        move(event: any) {
          if (that.enableDrag) {
          // || that.paramsInput.scale > 1
            let scale = (parseFloat(scaleElement.getAttribute('data-scale')) || 0)
             that.paramsInput.scale =  Math.max(0.5, Math.min(event.scale * scale, 8));




            let x = (parseFloat(scaleElement.getAttribute('data-x')) || 0) + event.dx;
            let y = (parseFloat(scaleElement.getAttribute('data-y')) || 0) + event.dy;
            scaleElement.style.transform = 'translate(' + x + 'px, ' + y + 'px) scale('+ that.paramsInput.scale + ')';
            scaleElement.setAttribute('data-x', x);
            scaleElement.setAttribute('data-y', y);
            that.paramsInput.x  = x;
            that.paramsInput.y  = y;

          }
        },
        end(event: any) {
          if (that.enableDrag) {
            callbackStopDrag && callbackStopDrag(that.paramsInput);

            // that.paramsInput.scale = that.paramsInput.scale * event.scale;
            // callbackStopDrag && callbackStopDrag(that.paramsInput);
          }
        }
      }
    })
  }

  public resizing(gestureArea: any = null, scaleElement: any = null) {
    let interactWithArea = interact(scaleElement);
    let that: any = this;

    interactWithArea
      .resizable({
        edges: {
          left: true,
          right: true,
          bottom: true,
          top: true
        },
        listeners: {
          move(event: any) {
            if (that.enableDrag) {
              let target = event.target;
              let x = (parseFloat(target.getAttribute('data-x')) || 0)*10;
              let y = (parseFloat(target.getAttribute('data-y')) || 0)*10;

              // update the element's style
              target.style.width = event.rect.width + 'px';
              target.style.height = event.rect.height + 'px';

              // translate when resizing from top or left edges
              x += event.deltaRect.left;
              y += event.deltaRect.top;

              target.style.transform = 'translate(' + x + 'px,' + y + 'px)';
              //
              target.setAttribute('data-x', x);
              target.setAttribute('data-y', y);
              // target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height);
            }
          }
        },
        modifiers: [
          // keep the edges inside the parent
          /* interact.modifiers.restrictEdges({
             outer: 'parent'
           }),*/

          // minimum size
          /* interact.modifiers.restrictSize({
             min: { width: 500, height: 500 }
           })*/
        ],

        inertia: true
      })
    /*.draggable({
      // @ts-ignore
      listeners: {move: window.dragMoveListener},
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
          endOnly: true
        })
      ]
    });*/
  }

  protected moveHtml(event: any, callback: any = null) {
    if (this.enableDrag) {
      callback && callback()
      let target = event.target;
      let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
      let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
      target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
     this.paramsInput.x= target.setAttribute('data-x', x);
      this.paramsInput.y = target.setAttribute('data-y', y);
      // this.paramsInput.x  = x;
      // this.paramsInput.y  = y;
      // this.paramsOutput.x  = x;
      // this.paramsOutput.y  = y;
    }
    /**/
    /*let target = event.target;
    // keep the dragged position in the data-x/data-y attributes
    let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
    let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

    // translate the element
    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

    // update the posiion attributes
    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);*/


    // @ts-ignore
    // window.dragMoveListener = dragMoveListener
  }

}





