import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {SidebarComponent} from "../../../common/sidebar/sidebar.component";
import {VideoReaderCourseComponent} from "../../../courses/video-reader-course/video-reader-course.component";
import {BoxServices} from "../../../../services/box/box.services";

@Component({
  selector: 'app-duo-video',
  standalone: true,
  providers: [],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    SidebarComponent,
    VideoReaderCourseComponent,
  ],
  templateUrl: './duo-video.component.html',
  styleUrls: ['./duo-video.component.scss'],
})
export class DuoVideoComponent implements OnInit {

  @Input() public nameBox: string;
  @Input() public pathBox: string;
  public videoSrc: string;
/*https://videos.pexels.com/video-files/6768212/6768212-uhd_1440_2732_30fps.mp4*/
  @Input() public name: string = 'Lesson';
  @Input() public desc: string = 'About art lesson ';
  @Input() steps: any[];
  public showChroma: any = true;
  public showReference: any = true;
  public referenceSrc: any = 'assets/cinema.png';
  @Output() public onClose = new EventEmitter();
  @Output() public onPrevLesson = new EventEmitter();
  protected showVideo: boolean = false;

  constructor(public boxServices: BoxServices) {
  }

  ngOnInit(): void {
    this.boxServices.findBoxFileByNameAndPath(this.nameBox, this.pathBox, (boxResult: any) => {
      this.videoSrc = boxResult.srcOriginal;
      console.log(boxResult)
    });
  }

}
