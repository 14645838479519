<app-sidebar [show]="true"
             (onSave)="onClose.emit()"
             [showClose]="true"
             [height]="100"
>

  <div *ngIf="videoSrc"
    class=" flex flex-col justify-center items-start row-start-1 sm:row-start-1">
    <div  class="w-full h-full">
      <app-video-reader-course [srcVideo]="videoSrc"> </app-video-reader-course>
    </div>

  </div>

</app-sidebar>
