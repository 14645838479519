import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {DrawCanvas} from "../../../../services/konva/DrawCanvas";
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {eraseHardIconImage} from "../../../../traits/iconsImages.services";

@Component({
  selector: 'app-brush-erase',
  standalone: true,
  providers: [],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
  ],
  templateUrl: './brush-erase.component.html',

})
export class BrushEraseComponent implements OnInit {
  @Input() items: any;
  @Output() onCollection = new EventEmitter();
  protected readonly eraseHardIconImage = eraseHardIconImage;

  constructor(public drawCanvas: DrawCanvas,) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }
}
