
<div
  [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
  class="  bg-[length:50px_50px] bg-opacity-10  bg-repeat w-full  bg-zinc-800 flex flex-col mb-1 mt-1"
     [style.height.px]="windowHeight()"
>
  <!-- Шапка -->
  <header class=" bg-zinc-700 shadow-md py-1 px-1 flex items-center justify-between mb-1">
    <div class="flex items-center space-x-4">
      <img [src]="srcIcon" alt="Logo" class="w-12 h-12 rounded-full shadow-md">
      <div>
        <h1 class="text-2xl font-bold text-gray-300">{{ name }}</h1>
        <p class="text-gray-200">{{ description }}</p>
      </div>
    </div>
  </header>

  <!-- Основное содержимое -->
  <main class="flex-1 flex items-center justify-center">
    <div class="wave-container relative">
      <div class="wave-item"  *ngFor="let lessonItem of lessons">
        <app-duo-btn [name]="lessonItem.name"
                     [srcIcon]="lessonItem.srcIcon"
                     [disabled]="false"
                     (onClick)="confettiAnimation();openLesson(lessonItem);"
        />
      </div>
      <div class="floating-duo shadow-md bg-zinc-600 rounded-full transition-colors p-1 animate-bounce-slow">
        <img class="w-16 h-16 text-[#AFAFAF]" src="assets/icons/duolingo/8995977.png" alt="Floating Duo">
      </div>
    </div>
  </main>

  <!-- Футер -->
  <footer class="bg-zinc-600 shadow-md py-4 mt-6 text-center text-zinc-300">
    {{footerText}}
  </footer>
</div>

<!--
<app-sidebar [show]="showLesson"
             (onSave)="closeLesson()"
             [showClose]="true"
             [height]="100"
             [ext]="'%'"
             [zIndex]="10000"
             [position]="'bottom'"
>


</app-sidebar>
-->
