  <div [hidden]="!visible"
       id="gesture-area"

       [style.opacity.%]="opacity"
       [style.pointer-events]="overlayDraw"
       [class.drag-handle]="lockMove"
       #gestureArea
       class="absolute z-20"
       [style.width.px]="width"
       [style.height.px]="height"
  >
<!--
windowWidth()/2
windowHeight()/2
'translate(' + xStart + 'px, ' + yStart + 'px)
-->
    <div
      [ngStyle]="{'transform': 'scale('+scaleStart+')'}"
      [attr.data-x]="xStart" [attr.data-y]="yStart"   #scaleElement
          id="scale-element" >

<ng-content></ng-content>
    </div>
    </div>



  <!--<app-hammer-wrapper
  (onClick)="onClick.emit()"
>-->
<!--</app-hammer-wrapper>-->


<!--<app-tool-image-eye-dropper
      [scaleX] = "scaleX"
      [id]="id"
      [showColors]="false"
      [heightImage]="height"
      [widthImage]="width"
      [src]="srcPreview"
      (onColor)="onColor.emit($event);"
    ></app-tool-image-eye-dropper>-->


<!-- <button *ngIf="model.overlayDraw == 'none' && model.showLock "
         class="btn-custom"
         (click)="
     model.lockMove = 'auto' ;
     model.overlayDraw = 'auto' ;
     model.opacity = 1;
     model.scaleX = 1;
     model.rotation = 0;
"
 >
   <i class="fa-solid fa-lock-open"></i>
 </button>
 <button *ngIf="model.overlayDraw == 'auto'  && model.showLock"
         class="btn-custom"
         (click)="
     model.lockMove = true ;
     model.overlayDraw = 'none' ;
     model.opacity = 0.3;
">
   <i class="fa-solid fa-lock"></i>
 </button>
 <button
   class=" btn-custom"
   (click)="model.visible =!model.visible">
   <i class="fa-solid fa-minimize"></i>
 </button>-->
<!--    [hidden]=" !model.visible"-->
  <!--<ng-container >
    <button class="btn-custom"
            *ngIf="model.lockMove === 'auto'"
            (click)="cd.enabledEyeDropper   = !cd.enabledEyeDropper; ">

      <i *ngIf="!cd.enabledEyeDropper && model.lockMove === 'auto'"
         class="fa-solid fa-eye-dropper"></i>
      <i *ngIf="cd.enabledEyeDropper && model.lockMove === 'auto'"
         class="fa-solid fa-arrows-to-eye"></i>
    </button>
  </ng-container>-->
  <!--<div class="images-container"
       [style.opacity]="model.opacity"
       [style.margin-left.px]="60"
       [style.pointer-events]="model.overlayDraw"
       [style.width.px]="width"
       [ngStyle]="{'transform': 'scaleX('+model.scaleX+')' +
     ' scaleY('+model.scaleY+')' +
      ' rotate('+model.rotation+'deg)' +
       ' translateX('+model.translateX+'px)' +
        ' translateY('+model.translateY+'px)'}"
  >
  </div>-->
