import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {PageDuoServices} from "../../../services/pages/page-duo.services";
import {PainterComponent} from "../../draw/painter/painter.component";
import {DrawZoneComponent} from "../../draw/canvas/draw-zone/draw-zone.component";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {
  arrowBackwardIcon,
  enterIcon,
  infoIcon,
  libsIcon,
  overlayIcon,
  palleteIcon,
  photosIcon,
  redoIcon,
  settingsIcon,
  undoIcon,
  videoIcon
} from "../../../traits/icons-tools";
import {Router} from "@angular/router";
import {windowHeight} from "../../../traits/window-tools";
import {PinchDrawZoneComponent} from "../../draw/draw-tools/pinch-draw-zone/pinch-draw-zone.component";
import {BrushLassoComponent} from "../../draw/brush/brush-lasso/brush-lasso.component";
import {OverlayCanvasComponent} from "../../draw/draw-tools/overlay-canvas/overlay-canvas.component";
import {BrushEraseComponent} from "../../draw/brush/brush-erase/brush-erase.component";
import {BrushUndoComponent} from "../../draw/brush/brush-undo/brush-undo.component";
import {DrawCanvas} from "../../../services/konva/DrawCanvas";
import {KnvColor} from "../../../services/konva/KnvColor";
import {BrushFillProgressComponent} from "../../draw/brush/brush-fill-progress/brush-fill-progress.component";
import {GifGeneratorComponent} from "../../image/gif-generator/gif-generator.component";
import {BrushColorHistoryComponent} from "../../draw/color/brush-color-history/brush-color-history.component";
import {EyeDropperComponent} from "../../eye-dropper/eye-dropper.component";
import {PinchReferenceComponent} from "../../draw/draw-tools/pinch-reference/pinch-reference.component";
import {RectangleWithColorComponent} from "../../rectangle-with-color/rectangle-with-color.component";
import {UploadImagesComponent} from "../../draw/upload-images/upload-images.component";
import {PinchExampleComponent} from "./pinch-example/pinch-example.component";
import {DuoStepsComponent} from "./duo-steps/duo-steps.component";
import {DuoVideoComponent} from "./duo-video/duo-video.component";
import {DuoInfoComponent} from "./duo-info/duo-info.component";
import {srcSketch} from "../../../traits/image-tools";
import {SketchConverterComponent} from "../../sketch-converter/sketch-converter.component";
import {CarouselWrapperComponent} from "../carousel-wrapper/carousel-wrapper.component";
import {BrushBrushComponent} from "../../draw/brush/brush-brush/brush-brush.component";
import {BrushEraseLassoComponent} from "../../draw/brush/brush-erase-lasso/brush-erase-lasso.component";
// @ts-ignore
// const driver:any = window.driver.js.driver;
import { driver } from "driver.js";
@Component({
  selector: 'app-duo-lesson',
  standalone: true,
  providers: [],
  imports: [
    NgForOf,
    NgStyle,
    NgClass,
    PainterComponent,
    DrawZoneComponent,
    SidebarComponent,
    PinchDrawZoneComponent,
    BrushLassoComponent,
    OverlayCanvasComponent,
    BrushEraseComponent,
    BrushUndoComponent,
    BrushFillProgressComponent,
    GifGeneratorComponent,
    NgIf,
    BrushColorHistoryComponent,
    EyeDropperComponent,
    PinchReferenceComponent,
    RectangleWithColorComponent,
    UploadImagesComponent,
    PinchExampleComponent,
    DuoStepsComponent,
    DuoVideoComponent,
    DuoInfoComponent,
    SketchConverterComponent,
    CarouselWrapperComponent,
    BrushBrushComponent,
    BrushEraseLassoComponent,
  ],
  templateUrl: './duo-lesson.component.html',
  styleUrls: ['./duo-lesson.component.scss'],
})
export class DuoLessonComponent {
  @Input() public imageSrc: string = 'https://via.placeholder.com/1200x200';
  @Input() public videoSrc: string;
  @Input() public name: string = 'Lesson';
  @Input() public desc: string = 'About art lesson ';
  @Input() public nameBox: string;
  @Input() public pathBox: string;
  @Input() steps: any[];

  @Output() public onNextLesson = new EventEmitter();
  @Output() public onPrevLesson = new EventEmitter();
  protected stepIndex: number = 0;
  protected brushType: 'brush'|'lasso'|'erase' = 'brush';
  protected showInfo: boolean =false;
  protected showSteps: boolean = false;
  protected showPalletePicker: boolean = false;
  protected showVideo: boolean = false;
  protected showHistory: boolean =false;
  protected showSettings: boolean = false;
  protected showOverlay: boolean = false;
  protected readonly videoIcon = videoIcon;
  protected readonly libsIcon = libsIcon;
  protected readonly photosIcon = photosIcon;
  protected readonly undoIcon = undoIcon;
  protected readonly redoIcon = redoIcon;
  protected readonly enterIcon = enterIcon;
  protected readonly arrowBackwardIcon = arrowBackwardIcon;
  protected readonly windowHeight = windowHeight;
  protected readonly imageSrcSketch = srcSketch;


  constructor(public dataPage: PageDuoServices, public router: Router, public drawCanvas: DrawCanvas, public color: KnvColor,) {
    // this.dataPage.getSite((site:any)=>{
    //   console.log(site)
    //   this.site = site;
    // });
  }

  ngAfterViewInit(){
  /*  const driverObj = driver({
      showProgress: true,
      steps: [
        { element: '.tutorial-brush', popover: { title: 'Title', description: 'Description' } },
        { element: '.tutorial-erase', popover: { title: 'Title', description: 'Description' } },
        { element: '.tutorial-video', popover: { title: 'Title', description: 'Description' } },
        { element: '.tutorial-steps', popover: { title: 'Title', description: 'Description' } },
        { element: '.tutorial-reference', popover: { title: 'Title', description: 'Description' } },
      ]
    });

    driverObj.drive();*/
  }
  protected goToPageChapters() {
    this.router.navigate(['duo/']);
  }
  protected stepsMap(steps: any[]) {
    return steps.map((item) => item.srcPreview)
  }

  protected readonly infoIcon = infoIcon;
  protected readonly settingsIcon = settingsIcon;

  protected readonly palleteIcon = palleteIcon;
  protected readonly overlayIcon = overlayIcon;
}
