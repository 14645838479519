<app-pinch-reference>
  <div [style.border]="'5px solid ' + drawCanvas.getBrushColor() +' '"
       [style.background-color]="drawCanvas.getBrushColor()"
  >
    <app-brush-color-history (onColor)="reInitChroma()"/>
    <app-eye-dropper *ngIf="(imageSrc  && showReferencePicker )"
                     [widthImage]="300"
                     [heightImage]="300"
                     [src]="imageSrc"
                     (onColor)="
                   drawCanvas.setBrushColorHex($event);reInitChroma();
                   color.triggerColorsInOtherTools($event,
                      true,
                      true,
                      true,
                      false
                      );
                  "
    ></app-eye-dropper>
    <app-rectangle-with-color
      *ngIf="(showChroma)"
      [orientation]="'hor'"
      [widthCanvas]="300"
      [heightCanvas]="135"
      [parentColor]="drawCanvas.getBrushColor()"
      (onColor)="drawCanvas.setBrushColorHex($event);
         color.triggerColorsInOtherTools($event,
                      true,
                      true,
                      true,
                      false)
"
    />
  </div>
</app-pinch-reference>
