<app-sidebar [show]="true"
             (onSave)="onClose.emit()"
             [showClose]="true"
             [height]="100"
             [ext]="'%'"
             [zIndex]="10000"
             [position]="'bottom'"
>
  <div id="list-feed"
       [style.background-image]="'url(' + bgImage + ')'"
       class="bg-[length:50px_50px] bg-opacity-10  bg-repeat min-h-screen flex justify-center items-center">
    <div class="mt-8 md:px-1 md:grid md:grid-cols-2 lg:grid-cols-3 gap-2 space-y-4 md:space-y-0">
      <ng-container
        *ngFor="let  step  of  steps; let i = index, let last = last;">
  <section [id]="step.id"
           class="bg-zinc-700 animate-fade-right animate-once m-4 max-w-sm  px-6 pt-6 pb-2 rounded-xl shadow-lg transform hover:scale-90 hover:scale-100 transition duration-500"
  >
    <h3
        style="font-family: peace_sans,serif;"
        class="mb-3 text-md tracking-wide text-amber-600">
      <span *ngIf="step.name">
       (step-{{ i+1 }})   {{ step.name }}
      </span>
      <span *ngIf="step.srcIcon"
            class="  absolute -top-3 right-0 rounded-full  border-4 ">
         <img
           class="rounded-lg w-[50px] h-[50px]"
           [src]="step.srcIcon"
           [alt]="step.name"
         >
       </span>
    </h3>
    <div class="relative">
      <img *ngIf="step.srcPreview "
           class="w-full rounded-xl"
           [src]="step.srcPreview"
           [alt]="step.name"

      />

      <p *ngIf="step.status"
         [class]="'bg-amber-300'"
         class=" absolute top-0 text-zinc-800 font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg">
        <span *ngIf="step.status">
          {{ step.status }}
        </span>
      </p>

     <!-- <p *ngIf="!step.costDiscount"
         [class]="bgClass"
         class=" absolute top-0 right-0 bg-amber-300 text-zinc-800 font-semibold py-1 px-3 rounded-tr-lg rounded-bl-lg"
      >
      </p>-->
    <!--  <p *ngIf="step.costDiscount"
         [class]="'bg-amber-300'"
         class=" absolute top-0 right-0 bg-amber-300 text-zinc-800 font-semibold py-1 px-3 rounded-tr-lg rounded-bl-lg"
      >
        <span>
          <span>{{ step.costDiscount }}</span>
        </span>
      </p>-->
    </div>
    <app-text-slice *ngIf="step.desc"
                    [text]="step.desc"
    ></app-text-slice>
  </section>
      </ng-container>
    </div>
  </div>
</app-sidebar>
