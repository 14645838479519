import {
  Component,
  Input,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {DrawCanvas} from "../../../../services/konva/DrawCanvas";
import {OverlayCanvasComponent} from "../../draw-tools/overlay-canvas/overlay-canvas.component";
import {getContext} from "../../../../services/common/canvas-tools";
import { countFilledPixels } from 'src/app/services/common/draw-methods';
import {confettiAnimation} from "../../../../traits/effects-animations";

@Component({
  selector: 'app-brush-fill-progress',
  standalone: true,
  providers: [],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    OverlayCanvasComponent,
  ],
  templateUrl: './brush-fill-progress.component.html',
  styleUrls: ['./brush-fill-progress.component.scss'],

})
export class BrushFillProgressComponent implements OnInit {
  @Input() change: any;
  @Input() public maxFilledPixels:any = 2000;
  @Input() public percentage:any = 0;
   public selectAlert:any;
   public alerts:any[] = [
    {percentage:10, message:'10%: Отлично! Начали рисовать!'},
    {percentage:20, message:'20%: Хорошо! Продолжайте!'},
    {percentage:50, message:'50%: Половина пути пройдена!'},
    {percentage:100, message:'100%: Поздравляю! Вы нарисовали!'},
  ];
  constructor(public drawCanvas: DrawCanvas) {
  }
  ngOnInit() {
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes['change'] && changes['change']?.previousValue != changes['change']?.currentValue) {
    this.drawCanvas.maxFilledPixels = this.maxFilledPixels
      this.progressDraw()
    }
  }
  switchMessenges(){
     /*switch (this.percentage) {*/
     /*  case 10:
         messageElem.textContent = "10%: Отлично! Начали рисовать!";
         confettiAnimation()
         break;*/
      /* case 20:
         messageElem.textContent = "20%: Хорошо! Продолжайте!";
         confettiAnimation()
         break;
       case 50:
         messageElem.textContent = "50%: Половина пути пройдена!";
         confettiAnimation()
         break;
       case 100:
         messageElem.textContent = "100%: Поздравляю! Вы нарисовали!";
         confettiAnimation()

         // showPopup();
         break;*/
    /* }*/

  }
progressDraw(){
  const progressElem:any = document.getElementById('progress');
  const updatePercentage  = (filledPixels:any,maxFilledPixels:any) => {
    this.drawCanvas.filledPixels =filledPixels;
     this.percentage = Math.floor((filledPixels / maxFilledPixels) * 100);
    // filledPixelsElem.textContent = filledPixels;
    // filledPercentElem.textContent = this.percentage;
    progressElem.style.width = `${this.percentage}%`;

  }
  updatePercentage(this.drawCanvas.isMouseMoveCounter,this.drawCanvas.maxFilledPixels)
  this.alertsHandler();
}
alertsHandler(){
  if(this.percentage == 10)   { this.selectAlert =this.alerts[0];}
  if(this.percentage == 20)   { this.selectAlert =this.alerts[1];}
  if(this.percentage == 50)   { this.selectAlert =this.alerts[2];}
  if(this.percentage == 100)   { this.selectAlert =this.alerts[3];confettiAnimation();}
}


}
