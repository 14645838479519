import {Component} from '@angular/core';
import {PageDuoServices} from "../../../services/pages/page-duo.services";
import {CommonHomeComponent} from "../../common-home/common-home.component";
import {
  NgClass,
  NgForOf,
  NgStyle
} from "@angular/common";
import {DuoChapterComponent} from "../../../components/duo/duo-chapter/duo-chapter.component";
import {ScrollWrapperComponent} from "../../../components/duo/scroll-wrapper/scroll-wrapper.component";
import {DuoLessonComponent} from "../../../components/duo/duo-lesson/duo-lesson.component";
import {
  ActivatedRoute,
  Router
} from "@angular/router";
import {BoxServices} from "../../../services/box/box.services";
import {PageCoursesServices} from "../../../services/pages/page-courses.services";
import {SidebarComponent} from "../../../components/common/sidebar/sidebar.component";
import {VideoReaderCourseComponent} from "../../../components/courses/video-reader-course/video-reader-course.component";
import {scrollTop} from "../../../traits/window-tools";

@Component({
  selector: 'app-page-duo-lesson',
  standalone: true,
  providers: [],
  imports: [
    CommonHomeComponent,
    NgForOf,
    NgStyle,
    NgClass,
    DuoChapterComponent,
    ScrollWrapperComponent,
    DuoLessonComponent,
    SidebarComponent,
    VideoReaderCourseComponent,
  ],
  templateUrl: './page-duo-lesson.component.html',
})
export class PageDuoLessonComponent {
  lesson: any = {name: 'alex'};
  /*public dataPage: PageDuoServices,*/

  constructor(public dataPage: PageDuoServices, private router: Router, private route: ActivatedRoute, public boxServices: BoxServices,) {
  }



  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dataPage.getSite((site:any)=>{
        this.lesson = this.findById(this.dataPage.getCards(site), params.slug);
       scrollTop();

      });
    });
  }
   findById(array, id) {
    for (const item of array) {
      // Проверяем, если у объекта есть нужный id, возвращаем его
      if (item.id === id) {
        return item;
      }

      // Если в объекте есть вложенный массив, запускаем поиск в нем
      if (Array.isArray(item.array)) {
        const found = this.findById(item.array, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }


}
