import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {
    NgClass,
    NgForOf,
    NgIf,
    NgStyle
} from "@angular/common";
import {BrushColorHistoryComponent} from "../../../draw/color/brush-color-history/brush-color-history.component";
import {EyeDropperComponent} from "../../../eye-dropper/eye-dropper.component";
import {PinchReferenceComponent} from "../../../draw/draw-tools/pinch-reference/pinch-reference.component";
import {RectangleWithColorComponent} from "../../../rectangle-with-color/rectangle-with-color.component";
import {DrawCanvas} from "../../../../services/konva/DrawCanvas";
import {KnvColor} from "../../../../services/konva/KnvColor";
import {CircleWithImageComponent} from "../../../circle-with-image/circle-with-image.component";

@Component({
  selector: 'app-pinch-example',
  standalone:true,
  providers:[],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    BrushColorHistoryComponent,
    EyeDropperComponent,
    PinchReferenceComponent,
    RectangleWithColorComponent,
    CircleWithImageComponent,
  ],
  templateUrl: './pinch-example.component.html',
  styleUrls: ['./pinch-example.component.scss'],
})
export class PinchExampleComponent {
  @Input() public  imageSrc: string  ='https://via.placeholder.com/1200x200';
  @Input() steps: any[];
  @Output() public  onNextLesson = new EventEmitter();
  @Output() public  onPrevLesson = new EventEmitter();
  protected showChroma: boolean = true;
  protected showReferencePicker: boolean = true;
  public showCamera: boolean = true;
  public showDownload: boolean = false;
  public showCropRigion: boolean = false;
  public showEyeDropper: boolean = false;
  public showPreload: boolean = false;
  public showCropRigionBtn: boolean = true;
  public showSettings: boolean = false;
  public isBlackAndWhite: boolean = true;
  public showDownloadBtn: boolean = true;
  imagePallete: string;
  constructor( public drawCanvas:DrawCanvas, public color:KnvColor,) {

  }
  protected reInitChroma() {
    this.showChroma = false;
    setTimeout(() => {
      this.showChroma = true;
    }, 50)
  }

}
