import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DrawCanvas} from "../../../../services/konva/DrawCanvas";
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle
} from "@angular/common";
import {
  brushesListIconImage,
  brushHardIconImage,
  filterIconImage,
  lassoIconImage
} from "../../../../traits/iconsImages.services";
import {countFilledPixels} from "../../../../services/common/draw-methods";

@Component({
  selector: 'app-brush-brush',
  standalone:true,
  providers:[],
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
  ],
  templateUrl: './brush-brush.component.html',
})
export class BrushBrushComponent implements OnInit {
  @Input() items: any;
  @Output() onCollection = new EventEmitter();

  constructor(
    public drawCanvas:DrawCanvas,
  ) {

  }

  ngOnInit() {
  }
  ngAfterViewInit() {
  }



  protected readonly brushHardIconImage = brushHardIconImage;
  protected readonly filterIconImage = filterIconImage;
  protected readonly lassoIconImage = lassoIconImage;
}
