import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SidebarModule} from 'primeng/sidebar';
import {NgIf} from '@angular/common';
import {FaIconComponent, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faCoffee, faRectangleXmark} from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    NgIf,
    SidebarModule,
    FaIconComponent,
    FontAwesomeModule,
  ],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  @Input() show: any = true;
  @Input() modal: any = false;
  @Input() headerText: any = 'text header';
  @Input() showHeader: any = false;
  @Input() showFooter: any = false;
  @Input() extHeight: any = '%';
  @Input() ext: any = '%';
  @Input() height: any = 100;
  @Input() position: any = 'top';
  @Input() width: any = 100;
  @Input() opacity: any = 1;
  @Input() showClose: any = true;
  @Input() zIndex: any = 100000;
  @Input() models: any = [];
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  public close() {
    this.show = false;
    this.onSave.emit(true);
  }

  public styles() {
    return {
      height: this.height + this.extHeight,
      width: this.width + this.ext,
      opacity: this.opacity
    };
  }


  protected readonly faRectangleXmark:IconDefinition = faRectangleXmark;
}
