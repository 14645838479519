<app-brush-undo [showSidebar]="showHistory" [changeAdd]="drawCanvas.isMouseUpCounter"
                (onClose)="showHistory =false"/>

<app-pinch-example *ngIf="showPalletePicker" [imageSrc]="imageSrc"/>
<!--<app-duo-info
  *ngIf="showInfo"
  (onClose)="showInfo = false"
/>-->
<app-duo-steps
  *ngIf="showSteps"
  [steps]="steps"
  (onClose)="showSteps = false"
/>
<app-duo-video
  *ngIf="(showVideo && nameBox && pathBox)"
  [nameBox]="nameBox"
  [pathBox]="pathBox"
  (onClose)="showVideo = false"
/>
<app-brush-fill-progress
  [change]="drawCanvas.isMouseMoveCounter"
  [maxFilledPixels]="10000"
/>
<div class="flex justify-center items-center h-full">

  <div class="bg-zinc-600 bg-opacity-80 rounded-lg shadow-lg p-1 w-full h-full flex flex-col space-y-1 relative">
    <div class="z-10  absolute top-[5px] right-[20px] ">
      <!-- <app-gif-generator *ngIf="steps"
                          [width]="75"
                          [height]="75"
                          [interval]="6"
                          [photos]="stepsMap(steps)">
       </app-gif-generator>-->
    </div>


    <div class="corner-box z-50  absolute top-[80px]   left-1/2 transform -translate-x-1/2    ">
      <div
        class="flex justify-between items-center bg-zinc-700 rounded-lg p-2 space-x-4 animate__animated animate__fadeInDown">

        <button
          class=" tutorial-reference w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-purple-400 transition ease-in-out duration-200"
          (click)="showPalletePicker = !showPalletePicker"
        >
           <span class="material-icons text-white">
             <i [class]="palleteIcon"></i>
           </span>
        </button>
        <button
          class=" tutorial-reference w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-purple-400 transition ease-in-out duration-200"
          (click)="showOverlay = !showOverlay"
        >
           <span class="material-icons text-white">
             <i [class]="overlayIcon"></i>
           </span>
        </button>
      </div>

    </div>

    <!-- Изображение и заголовок урока -->
    <div class="relative bg-zinc-800 rounded-lg overflow-hidden shadow-md">
      <div class="back_and_info absolute z-50 top-0 left-0">
        <button
          class=" m-2 bg-zinc-700 rounded-lg p-2 space-x-4  w-8 h-8   flex justify-center items-center hover:bg-blue-400 transition ease-in-out duration-200"
          (click)="goToPageChapters()"
        >
      <span class="material-icons text-white">
             <i [class]="arrowBackwardIcon"></i>
      </span>
        </button>
        <button
          class="  m-2 bg-zinc-700 rounded-lg p-2 space-x-4  w-8 h-8   flex justify-center items-center hover:bg-blue-400 transition ease-in-out duration-200"
          (click)="showInfo = !showInfo"
        >
      <span class="material-icons text-white">
             <i [class]="infoIcon"></i>
      </span>
        </button>
      </div>
      <div class="steps_and_video absolute z-50 top-0 right-0">
        <button
          class=" m-2 bg-zinc-700 rounded-lg p-2 space-x-4  w-8 h-8   flex justify-center items-center hover:bg-blue-400 transition ease-in-out duration-200"
          (click)="showSteps = !showSteps"
        >
            <span class="material-icons text-white">
                   <i [class]="photosIcon"></i>
            </span>
        </button>
        <button
          class=" m-2 bg-zinc-700 rounded-lg p-2 space-x-4  w-8 h-8   flex justify-center items-center hover:bg-blue-400 transition ease-in-out duration-200"
          (click)="showVideo = !showVideo"
        >
            <span class="material-icons text-white">
                   <i [class]="videoIcon"></i>
            </span>
        </button>
      </div>
      <img [src]="imageSrc"
           [alt]="name"
           class="w-full h-[90px] object-cover"
      >
      <div class="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-center items-center p-4 space-y-2">
        <p class="text-2xl md:text-3xl gradient-text animate__animated animate__fadeInDown">
          {{ name }}
        </p>
        <p class="text-sm md:text-base text-white text-center animate__animated animate__fadeInUp">
          {{ desc }}
        </p>
      </div>
    </div>

    <div
      class="bg-zinc-800 rounded-lg p-6 flex justify-center items-center relative animate__animated animate__pulse animate__infinite">
      <!--[height]="windowHeight()-180"  [style.height.px]="windowHeight()-100"  -->
      <div class="w-full h-100 rounded-lg shadow-md">
        <!--        <app-carousel-wrapper
                  [images]="stepsMap(steps)"
                  (onIndex)="stepIndex = $event"
                >
                  <app-overlay-canvas *ngIf="imageSrc" [imageSrc]="imageSrc">
                    <img [src]="steps[stepIndex].srcPreview" alt="Image"/>
                  </app-overlay-canvas>
                </app-carousel-wrapper>-->
        <app-overlay-canvas *ngIf="(showOverlay && imageSrc)" [imageSrc]="imageSrc">
          <app-sketch-converter [imageSrc]="imageSrc"></app-sketch-converter>
        </app-overlay-canvas>

        <!--              <app-pinch-draw-zone>-->

        <app-draw-zone/>
        <!--              </app-pinch-draw-zone>-->
      </div>

      <div *ngIf="drawCanvas.isMouseUpCounter == 0"
           class=" sidebar corner-box bottom-0 left-0 m-4">
        <!-- Очистка экрана -->
        <div
          class="flex justify-start items-center bg-zinc-700 rounded-lg p-2 space-x-4 animate__animated animate__fadeInDown">
          <button
            class="click-animation h-[40px] w-[40px] p-1 bg-zinc-700 text-zinc-200"
            (click)="showHistory = true">
            <i [class]="undoIcon"></i>
            <span class="text-[20px]">{{ drawCanvas.isMouseUpCounter }}</span>
          </button>
        </div>

      </div>
      <!-- <div class="corner-box bottom-0 right-0 m-2">
         <div
           class="flex justify-start items-center bg-zinc-700 rounded-lg p-2 space-x-2 animate__animated animate__fadeInDown">
           &lt;!&ndash;refactor&ndash;&gt;
         </div>
       </div>-->
      <div class=" tutorial-brush   corner-box z-50  top-0 left-[25px] m-2">
        <div
          class="flex justify-start items-center bg-zinc-700 rounded-lg p-2 space-x-2 animate__animated animate__fadeInDown">
          <app-brush-lasso/>
          <div class="relative">
            <button
              (click)="showSettings = !showSettings "
              class="absolute z-50 -top-3.5 -right-3.5 click-animation h-[20px] w-[20px] p-[1px]   bg-zinc-700 text-zinc-200"
            >
              <i [class]="settingsIcon"></i>
            </button>
            <app-brush-brush/>
          </div>


        </div>
      </div>
      <div class=" tutorial-erase corner-box z-50  top-0 right-[25px] m-2">
        <div
          class="flex justify-start items-center bg-zinc-700 rounded-lg p-2 space-x-2 animate__animated animate__fadeInDown"
        >
          <div class="relative">
            <button
              (click)="showSettings = !showSettings "
              class="absolute z-50 -top-3.5 -right-3.5 click-animation h-[20px] w-[20px] p-[1px]   bg-zinc-700 text-zinc-200"
            >
              <i [class]="settingsIcon"></i>
            </button>
          <app-brush-erase/>
          </div>
          <app-brush-erase-lasso/>
        </div>
      </div>
    </div>

    <!-- Нижняя панель управления уроком -->
    <!-- <div
       class="flex justify-between items-center bg-zinc-700 rounded-lg p-2 space-x-2 animate__animated animate__fadeInUp">
       <button
         class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-blue-400 transition ease-in-out duration-200"
         (click)="onPrevLesson.emit()"
       >
       <span class="material-icons text-white">
              <i [class]="undoIcon"></i>

       </span>
       </button>
       <button
         class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-red-400 transition ease-in-out duration-200"
         (click)="startLesson()"

       >
       <span class="text-white">
              <i [class]="enterIcon"></i>
       </span>
       </button>
       <button
         class="w-8 h-8 bg-zinc-300 rounded-full flex justify-center items-center hover:bg-blue-400 transition ease-in-out duration-200"
         (click)="onNextLesson.emit()"

       >
       <span class="text-white">
              <i [class]="redoIcon"></i>
       </span>
       </button>
     </div>-->
  </div>
</div>
